/* eslint-disable no-useless-escape */
import { Buffer } from 'buffer'

import axios, { AxiosResponse } from 'axios'
import { DgcPhotoMode } from './api/types'

export const uploadFile = async (
  url: string,
  file: File | Blob,
  fileType: string
) => {
  const headers = {
    'Content-Type': fileType,
    // "x-amz-acl": "public-read",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'PUT,POST,DELETE',
  }

  const response = (await axios.put(url, file, {
    headers,
  })) as AxiosResponse<{
    url: string
  }>

  return response.data
}

function converyBase64ToUnit8Array(base64String: string) {
  let n = base64String.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = base64String.charCodeAt(n)
  }

  return u8arr
}

export function dataURLtoFile(dataurl: string, filename: string) {
  // Data URLs are composed of four parts:
  // a prefix (data:), a MIME type indicating the type of data,
  // an optional base64 token if non-textual, and the data itself:
  // data:[<mediatype>][;base64],<data>
  // more info: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
  const arr = dataurl.split(',')

  const match = arr[0].match(/:(.*?);/)
  const mime = match ? match[1] : null
  if (mime === null) {
    throw new Error('Missing mime tye')
  }
  const base64String = Buffer.from(arr[1], 'base64').toString()
  const u8arr = converyBase64ToUnit8Array(base64String)

  return new File([u8arr], filename, { type: mime })
}

export const readFile = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        return resolve(reader.result as string)
      },
      false
    )

    reader.readAsDataURL(file)
  })

export function easInOut(p: number) {
  return 0.5 - Math.cos(p * Math.PI) / 2
}

export const sleep = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const hex2rgba = (hex: string, alpha: number = 1) => {
  const [r, g, b] = (hex.match(/\w\w/g) as string[]).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const uniqueId = () =>
  Math.random().toString(16).slice(2) + Math.random().toString(16).slice(2)

export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const resizeImageFromBase64String = async (
  base64Str: string,
  maxWidth: number,
  maxHeight: number
): Promise<{
  dataUrl: string
  width: number
  height: number
  mode: DgcPhotoMode
}> => {
  return new Promise((resolve) => {
    var img = new Image()
    img.src = base64Str

    img.onload = () => {
      const canvas = document.createElement('canvas')
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      ctx.drawImage(img, 0, 0, width, height)
      return resolve({
        dataUrl: canvas.toDataURL(),
        width,
        height,
        mode: width >= height ? DgcPhotoMode.LANDSCAPE : DgcPhotoMode.PORTRAIT,
      })
    }
  })
}

export const preloadPhotoByCSSInjection = (url: string) => {
  const encodedUrl = encodeURI(url)
  let link = document.getElementById('photo-preload')
  if (!link) {
    link = document.createElement('link')
    link.id = 'photo-preload'
    link.setAttribute('href', encodedUrl)
    link.setAttribute('rel', 'preload')
    link.setAttribute('as', 'image')
    document.head.appendChild(link)
  } else {
    link.setAttribute('href', encodedUrl)
  }
}

declare global {
  interface Window {
    opera: any
    webkit: any
  }
}

// Based on https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export const isMobileBrowser = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export function isDeviceIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str))
}

export const compactObject = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((agg, key) => {
    const val = obj[key]
    if (val === null || val === undefined) {
      return agg
    }
    return {
      ...agg,
      [key]: val,
    }
  }, {})
}

const US_TIMEZONES = [
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Shiprock',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'America/Metlakatla',
  'Pacific/Honolulu',
]

export const isCurrentTimezoneUs = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  return US_TIMEZONES.includes(tz)
}

export const isCurrentTimezoneUk = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  return ['Europe/London'].includes(tz)
}

export const joinElements = (
  elements: string[],
  separator: string = ', ',
  conjunction: string = 'and'
): string => {
  if (!elements || elements.length === 0) {
    return ''
  }

  if (elements.length === 1) {
    return elements[0]
  }

  const joinedElements = `${elements
    .slice(0, elements.length - 1)
    .join(separator)} ${conjunction} ${elements[elements.length - 1]}`
  return joinedElements
}
